module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"mtchtdd","accessToken":"MC5ZV3JLV1JRQUFDSUF4UjE2.77-9eu-_vV3vv73vv73vv71NO--_ve-_ve-_vXApHu-_vRfvv73vv73vv73vv73vv73vv73vv73vv71LaO-_vXbvv73vv73vv70","promptForAccessToken":true,"apiEndpoint":"https://mtchtdd.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mtchtdd-com","short_name":"mtchtdd","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"/opt/build/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f7aeaae9e02ba4cebbc9afcdba26b6ef"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
