import React from 'react'

const Logo = () => (
  <svg
    width="217"
    height="73"
    viewBox="0 0 217 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clippath="url(#clip0_3_2)">
      <path
        d="M1.91406 0.693726H9.07885L18.3905 26.1319H18.5328L27.8056 0.693726H34.9704V33.7634H29.5127V8.69783H29.4222L20.6279 33.7634H16.2048L7.41052 8.69783H7.30705V33.7634H1.91406V0.693726Z"
        fill="currentColor"
      />
      <path
        d="M45.7693 0.693726V33.7634H40.2341V0.693726H45.7693Z"
        fill="currentColor"
      />
      <path
        d="M58.7538 5.37027H48.0972V0.693726H74.9069V5.37027H64.302V33.7634H58.7538V5.37027Z"
        fill="currentColor"
      />
      <path
        d="M90.6075 4.71509C83.4297 4.71509 80.5974 10.6893 80.5974 17.203C80.5974 23.7167 83.4297 29.7422 90.6075 29.7422C96.8023 29.7422 99.169 25.7209 99.544 21.6868H105.079C104.342 29.1384 99.2207 34.4573 90.7497 34.4573C81.1535 34.4573 75.101 27.1984 75.101 17.203C75.101 7.20756 81.1535 -0.0128174 90.7497 -0.0128174C99.169 -0.0128174 104.29 5.30612 105.079 12.0639H99.544C99.2932 9.93173 98.2241 7.97778 96.5595 6.6089C94.8949 5.24002 92.7629 4.56165 90.6075 4.71509Z"
        fill="currentColor"
      />
      <path
        d="M114.236 0.693726V14.3893H129.962V0.693726H135.51V33.7634H129.962V19.1043H114.236V33.7634H108.688V0.693726H114.236Z"
        fill="currentColor"
      />
      <path
        d="M162.54 14.3379V19.053H146.283V29.0869H165.282V33.7634H140.709V0.693726H164.777V5.37027H146.283V14.3636L162.54 14.3379Z"
        fill="currentColor"
      />
      <path
        d="M174.878 0.693726V29.0869H191.496V33.7634H169.33V0.693726H174.878Z"
        fill="currentColor"
      />
      <path
        d="M200.394 0.693726V29.0869H217.013V33.7634H194.846V0.693726H200.394Z"
        fill="currentColor"
      />
      <path
        d="M10.6696 43.9131H0V39.2366H26.8227V43.9131H16.2048V72.3062H10.6696V43.9131Z"
        fill="currentColor"
      />
      <path
        d="M27.0037 55.7457C27.0037 45.8016 33.2891 38.5427 43.3508 38.5427C53.4126 38.5427 59.685 45.8016 59.685 55.7457C59.685 65.6897 53.3608 73 43.3508 73C33.3408 73 27.0037 65.6897 27.0037 55.7457ZM54.1627 55.7457C54.1627 49.0906 50.9941 43.2578 43.3638 43.2578C35.7334 43.2578 32.5131 49.0906 32.5131 55.7457C32.5131 62.4007 35.7205 68.2848 43.3638 68.2848C51.0071 68.2848 54.1497 62.4521 54.1497 55.7457H54.1627Z"
        fill="currentColor"
      />
      <path
        d="M63.6425 39.2366H76.5753C85.7447 39.2366 91.3834 45.1593 91.3834 55.7971C91.3834 66.4349 85.9516 72.3062 76.7305 72.3062H63.6425V39.2366ZM69.1906 67.6298H76.3166C83.753 67.6298 85.8481 62.131 85.8481 55.7971C85.8481 49.4633 83.753 43.9131 76.3166 43.9131H69.1906V67.6298Z"
        fill="currentColor"
      />
      <path
        d="M95.3538 39.2366H108.287C117.456 39.2366 123.082 45.1593 123.082 55.7971C123.082 66.4349 117.637 72.3062 108.429 72.3062H95.3538V39.2366ZM100.889 67.6298H108.015C115.464 67.6298 117.559 62.131 117.559 55.7971C117.559 49.4633 115.464 43.9131 108.015 43.9131H100.889V67.6298Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_2">
        <rect width="217" height="73" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Logo
