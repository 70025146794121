import React from 'react'

const ThursdayLogo = () => (
  <svg
    width="406"
    height="35"
    viewBox="0 0 406 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5915 33.8556H16.0954V5.45732H26.6407V0.78595H0V5.45732H10.5915V33.8556Z"
      fill="currentColor"
    />
    <path
      d="M30.3968 0.78595V33.8556H35.9007V19.1939H51.5336V33.8556H57.0375V0.78595H51.5336V14.4763H35.9007V0.78595H30.3968Z"
      fill="currentColor"
    />
    <path
      d="M88.3145 22.5703V0.78595H82.8569V22.5703C82.8569 27.5654 80.3593 29.8317 75.2717 29.8317C70.2303 29.8317 67.7327 27.5654 67.7327 22.5703V0.78595H62.2288V22.5703C62.2288 29.7854 66.0677 34.5493 75.2717 34.5493C84.4757 34.5493 88.3145 29.7854 88.3145 22.5703Z"
      fill="currentColor"
    />
    <path
      d="M93.5406 33.8556H99.0445V21.0902H107.138C115.094 21.0902 111.995 32.468 113.521 33.8556H119.302V33.5318C117.545 32.9768 120.32 20.0264 112.689 18.5926V18.5001C116.481 17.5751 118.84 14.8926 118.84 10.2674C118.84 4.25479 114.446 0.78595 107.647 0.78595H93.5406V33.8556ZM99.0445 5.45732H106.491C109.867 5.45732 113.382 6.15109 113.382 10.9149C113.382 15.7251 109.867 16.3726 106.491 16.3726H99.0445V5.45732Z"
      fill="currentColor"
    />
    <path
      d="M135.885 14.1525C131.26 12.9963 128.161 12.3487 128.161 8.74114C128.161 6.28983 130.52 4.80979 134.451 4.80979C139.03 4.80979 141.435 6.84484 141.944 10.6837H147.448C146.985 3.88476 141.713 0.0921631 134.451 0.0921631C127.236 0.0921631 122.703 3.376 122.703 9.2499C122.703 15.5401 128.3 17.5289 133.48 18.8239C140.001 20.4427 142.823 21.1827 142.823 25.1141C142.823 27.6579 140.926 29.8317 136.024 29.8317C130.104 29.8317 127.236 27.0566 126.958 22.8478H121.455C121.778 30.3405 127.93 34.5493 136.024 34.5493C144.349 34.5493 148.327 30.1555 148.327 24.8828C148.327 17.4826 142.961 15.9563 135.885 14.1525Z"
      fill="currentColor"
    />
    <path
      d="M152.619 33.8556H165.616C174.774 33.8556 180.185 27.9816 180.185 17.3439C180.185 6.70611 174.589 0.78595 165.477 0.78595H152.619V33.8556ZM158.123 5.45732H165.2C172.6 5.45732 174.681 11.0075 174.681 17.3439C174.681 23.6803 172.6 29.1842 165.2 29.1842H158.123V5.45732Z"
      fill="currentColor"
    />
    <path
      d="M202.077 26.0391L204.759 33.8556H210.356L198.238 0.78595H192.456L180.292 33.8556H185.889L188.571 26.0391H202.077ZM190.098 21.4602L195.324 5.82733H195.416L200.597 21.4602H190.098Z"
      fill="currentColor"
    />
    <path
      d="M207.168 0.78595L218.916 20.7202V33.8556H224.42V20.7202L236.167 0.78595H230.293L221.691 16.1413H221.598L213.042 0.78595H207.168Z"
      fill="currentColor"
    />
    <path
      d="M236.452 34.2256C238.117 34.2256 239.412 33.0693 239.412 31.2192C239.412 29.4154 238.117 28.2129 236.452 28.2129C234.741 28.2129 233.446 29.4154 233.446 31.2192C233.446 33.0693 234.741 34.2256 236.452 34.2256Z"
      fill="currentColor"
    />
    <path
      d="M256.346 14.1525C251.721 12.9963 248.622 12.3487 248.622 8.74114C248.622 6.28983 250.981 4.80979 254.912 4.80979C259.491 4.80979 261.896 6.84484 262.405 10.6837H267.909C267.446 3.88476 262.174 0.0921631 254.912 0.0921631C247.697 0.0921631 243.164 3.376 243.164 9.2499C243.164 15.5401 248.761 17.5289 253.941 18.8239C260.462 20.4427 263.284 21.1827 263.284 25.1141C263.284 27.6579 261.387 29.8317 256.485 29.8317C250.565 29.8317 247.697 27.0566 247.419 22.8478H241.916C242.239 30.3405 248.391 34.5493 256.485 34.5493C264.81 34.5493 268.787 30.1555 268.787 24.8828C268.787 17.4826 263.422 15.9563 256.346 14.1525Z"
      fill="currentColor"
    />
    <path
      d="M280.285 33.8556H285.789V5.45732H296.334V0.78595H269.693V5.45732H280.285V33.8556Z"
      fill="currentColor"
    />
    <path
      d="M325.713 22.5703V0.78595H320.256V22.5703C320.256 27.5654 317.758 29.8317 312.67 29.8317C307.629 29.8317 305.132 27.5654 305.132 22.5703V0.78595H299.628V22.5703C299.628 29.7854 303.466 34.5493 312.67 34.5493C321.874 34.5493 325.713 29.7854 325.713 22.5703Z"
      fill="currentColor"
    />
    <path
      d="M330.939 33.8556H343.936C353.094 33.8556 358.505 27.9816 358.505 17.3439C358.505 6.70611 352.909 0.78595 343.797 0.78595H330.939V33.8556ZM336.443 5.45732H343.52C350.92 5.45732 353.001 11.0075 353.001 17.3439C353.001 23.6803 350.92 29.1842 343.52 29.1842H336.443V5.45732Z"
      fill="currentColor"
    />
    <path
      d="M363.01 0.78595V33.8556H368.514V0.78595H363.01Z"
      fill="currentColor"
    />
    <path
      d="M389.421 34.5493C399.365 34.5493 405.655 27.2416 405.655 17.2976C405.655 7.3536 399.365 0.0921631 389.421 0.0921631C379.43 0.0921631 373.187 7.3536 373.187 17.2976C373.187 27.2416 379.43 34.5493 389.421 34.5493ZM389.421 29.8317C381.836 29.8317 378.644 24.004 378.644 17.2976C378.644 10.6374 381.836 4.80979 389.421 4.80979C397.006 4.80979 400.151 10.6374 400.151 17.2976C400.151 24.004 397.006 29.8317 389.421 29.8317Z"
      fill="currentColor"
    />
  </svg>
)

export default ThursdayLogo
